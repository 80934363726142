import React from 'react';
import './HomeBanner.scss';

const HomeBanner = () => (
	<div className="vhomeBanner flex-row-center">
		<div className="content">
			<div className="heading font-head-bold uppercase text-align-center">
				VERTICAL CREATIVE SOLUTION
			</div>
		</div>
	</div>
);

export default HomeBanner;
