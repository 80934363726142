import React from 'react';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import { getMonthName } from '../../utils/date';
import { getCdnUrl } from '../../utils/string';
import { connect } from 'react-redux';
import { fetchCreative } from './../../redux/creative/CreativeActions';
import { openDialogModal } from './../../redux/creative/CreativeActions';
import './CreativeCard.scss';
import ImageLoader from './../ImageLoader';
const CreativeCard = ({ creative, fetchCreative, openModalBox }) => {
	const creativeDate = new Date(creative.campaign_created_at);
	const formattedDate = `${getMonthName(creativeDate.getMonth())} ${creativeDate.getFullYear()}`;
	const openCreativeModal = id => {
		openModalBox(id);
	};
	const [loaded, setLoaded] = React.useState(false);

	function handleImageLoad() {
		setLoaded(true);
	}

	return (
		<div className="creativeCard">
			<Card
				className="creativeCardWrapper"
				title={creative.title}
				onClick={() => {
					openCreativeModal(creative);
				}}
			>
				<CardActionArea className="creativeCardActionArea">
					<div className="creativeImageCtn">
						<div style={{ visibility: loaded ? 'visible' : 'hidden' }}>
							<img
								src={creative.thumbnail ? getCdnUrl(creative.thumbnail.url) : ''}
								onLoad={handleImageLoad}
								className="creativeImage"
								alt="creative-thumbnail"
							/>
						</div>
						<div
							style={{ visibility: loaded ? 'hidden' : 'visible' }}
							className="imageLoaderContainer text-align-center flex-row-center "
						>
							<div>
								<ImageLoader />
							</div>
						</div>
					</div>
					<div className="cardContent">
						<div className="cardViewBtn uppercase text-align-center">View</div>
						<div className="cardTitle">{creative.title}</div>
						<div className="cardRegionDate flex-row-space-between">
							{creative.region ? `${creative.region.name}, ${formattedDate}` : `${formattedDate}`}
						</div>
					</div>
				</CardActionArea>
			</Card>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		fetchCreative: id => dispatch(fetchCreative(id)),
		openModalBox: id => dispatch(openDialogModal(id)),
	};
};
export default connect(null, mapDispatchToProps)(CreativeCard);
