import React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon from '@mui/material/Icon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';

import logo from '../assets/images/creative-labs-logo.png';
import { fireEvent } from '../utils/tracking';

import './AppDrawer.scss';

const AppDrawer = ({ drawerOpen, toggleDrawer, menuItems, isLoggedInn }) => {
	return (
		<SwipeableDrawer
			anchor={'left'}
			open={drawerOpen}
			onOpen={() => toggleDrawer(true)}
			onClose={() => toggleDrawer(false)}
		>
			<div
				className="appDrawerContents"
				onClick={() => toggleDrawer(false)}
				onKeyDown={() => toggleDrawer(false)}
			>
				<List>
					<Link to="/" className="appDrawerLogo flex-row-valign">
						<ListItem>
							<div className="logoCtn flex-column-center">
								<img src={logo} alt="ThinkLoud Logo" />
							</div>
						</ListItem>
					</Link>
				</List>
				<Divider />
				<List className="listContainer">
					{menuItems.map(item => (
						<Link
							to={item.link}
							className="menuItem"
							key={item.name}
							onClick={() => fireEvent('App Drawer', 'Click', `${item.name} Clicked`)}
						>
							<ListItem button>
								<ListItemText primary={item.name} className="menuItemText" />
							</ListItem>
						</Link>
					))}
					{!isLoggedInn ? (
						<ListItem button>
							<ListItemText primary="LOGIN" className="menuItemText" />
						</ListItem>
					) : (
						<ListItem button>
							<ListItemIcon className="menuItemIcon">
								<Icon>home</Icon>
							</ListItemIcon>
							<ListItemText primary="LOGOUT" className="menuItemText" />
						</ListItem>
					)}
				</List>
			</div>
		</SwipeableDrawer>
	);
};

export default AppDrawer;
