import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Authorize.scss';

const Authorize = () => {
	const [timeLeft, setTimeLeft] = useState(3);
	const location = useLocation();

	useEffect(() => {
		const timer = setInterval(() => {
			if (timeLeft === 1) {
				clearInterval(timer);
				localStorage.setItem('ru', location.pathname);
				window.location.href = `${process.env.REACT_APP_API_URL}/connect/microsoft`;
			} else setTimeLeft(prevTime => prevTime - 1);
		}, 1000);
		return () => clearInterval(timer);
	}, [timeLeft, location.pathname]);

	return (
		<div className="authorize flex-column-valign">
			<div className="lockImageContainer">
				<img
					src="https://inmobirmapp.blob.core.windows.net/inmobi-rm-app/CreativeRepository/lock_1dfac2af25.png"
					alt="Lock Icon"
					className="lockIcon"
				/>
			</div>

			<h4>Sorry! You don't have permission to view this page</h4>
			<span>Please login to access this page</span>
			<span>Redirecting in {timeLeft} seconds</span>
		</div>
	);
};

export default Authorize;
