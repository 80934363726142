import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import { Router, globalHistory } from '@reach/router';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
// import ScrollToTop from './ScrollToTop';
import Home from '../pages/Home';
// import CreativesOld from '../pages/CreativesOld';
import CreativeDetail from '../pages/CreativeDetail';
import FunctionalitiesList from '../pages/Functionalities';
import Faqs from '../pages/Faqs';
import LoginRedirect from '../pages/LoginRedirect';
import NotFound from '../pages/NotFound';
import Footer from './Footer';
import { pageView } from '../utils/tracking';
import AdFormatDetails from '../pages/AdFormatDetails';
import Creatives from '../pages/Creatives';
import VerticalDetails from '../pages/VerticalDetails';
import VerticalsPage from '../pages/Verticals';
import {createTheme} from '@mui/material';
import {ThemeProvider} from '@mui/styles';
const theme = createTheme()
const AppRouter = props => {
	// useEffect(() => {
	// 	const unsubscribeHistory = globalHistory.listen(({ location }) => {
	// 		pageView();
	// 	});
	// 	return () => unsubscribeHistory();
	// }, []);

	return (
		
		<Provider store={props.store}>
			{/* <Router primary={false}>
				{/* <ScrollToTop path="/"> 
				<Home path="/" />
				{/* <Creatives path="/creative" /> 

				<CreativeDetail path="/creative/:creativeId" />
				<FunctionalitiesList path="/functionalities" />
				<Creatives path="/gallery" />
				{/* <FunctionalityDetail path="/docs/:functionalityId" /> 
				<Faqs path="/faqs" />
				<LoginRedirect path="/connect/microsoft/redirect" />
				<AdFormatDetails path="/functionality/:functionalityId" />
				<NotFound default />

				<VerticalDetails path="/vertical/:verticalId" />
				<VerticalsPage path="/verticals" />
				{/* </ScrollToTop> 
			</Router> */}
			{/* <BrowserRouter> */}
			<ThemeProvider theme={theme}>
				<Router>
					<Routes>
						<Route path="/" element={<Home />}/>
						<Route index element={<Home />} />
						<Route exact path="/creative/:creativeId" element={<CreativeDetail />} />
						<Route exact path="/functionalities" element={<FunctionalitiesList />} />
						<Route path="/gallery" element={<Creatives />} />
						<Route path="/verticals" element={<VerticalsPage />} />
						{/* <Route path="*" element={<NoPage />} /> */}
						
					</Routes>
				</Router>
			</ThemeProvider>
				{/* </BrowserRouter> */}
			{/* <Footer /> */}
		</Provider>
		
	);
};

AppRouter.propTypes = {
	store: PropTypes.object.isRequired,
};

export default AppRouter;
