import React from 'react';
import FilterType from './FilterType';
import { connect } from 'react-redux';
import './FiltersSection.scss';
import NoMockSwitch from './NoMockSwitch';
const FiltersSection = ({ filters }) => {
	return Object.keys(filters).length ? (
		<>
			<div className="filterSection flex-row-space-between">
				{Object.keys(filters).map(filterType => (
					<FilterType name={filterType} filterItems={filters[filterType]} key={filterType} />
				))}
				<div className="filterType">
					<NoMockSwitch />
				</div>
			</div>
		</>
	) : (
		<></>
	);
};

const mapStateToProps = state => {
	return {
		filters: state.creativeReducer.filters,
	};
};

export default connect(mapStateToProps)(FiltersSection);
