import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import hexicon from '../../assets/images/hexicon.png';
import { makeStyles } from '@mui/styles';
import CreativeTypeCardDetails from '../Common/CreativeTypeCardDetails';

import './VerticalDetails.scss';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: '5px',
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

const AdFormatDetails = ({ details }) => {
	const navigate = useNavigate();
	let desc = details.description;
	if (desc !== '' && desc !== undefined) {
		const containsString = details.description.search('\n');
		if (containsString !== -1) {
			desc = desc.replace(/(?:\r\n|\r|\n)/g, '\n \n');
		}
	}
	return (
		<>
			<div className="verticalMainContainer">
				<div className="adFormatDetails-Container text-align-center">
					<div className="ContainerContent flex-row-space-between">
						<div className="backButtonContent">
							<IconButton
								className="backBtn"
								onClick={() => {
									navigate(-1);
								}}
							>
								<ArrowBackIcon className="backIcon" />
							</IconButton>
						</div>
						<div className="leftArea">
							<div className="leftContainerHeader">
								<div className="title">VERTICAL SOLUTIONS</div>
								<div className="formatType">{details.name}</div>
								<div className="techDetails">
									<CreativeTypeCardDetails details={details} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="topIconLiner">
					<div className="iconContainer">
						<img src={hexicon} alt="hex-icon" />
					</div>
				</div>
			</div>
		</>
	);
};
export default AdFormatDetails;
