import React from 'react';
import './Error.scss';
import PageNotFound from '../components/PageNotFound';
const Error = ({ error }) => {
	// return (<div className="errorMsg">{`Error: ${error.message}`}</div>);

	return error.createNotFound ? (
		<>
			<div className="pageNotFound flex-row-center">404 Creative does not exist</div>
		</>
	) : error.message ? (
		<div className="errorMsg text-align-center">{`Error: ${error.message}`}</div>
	) : (
		<div className="errorMsg text-align-center">{`Error: ${error}`}</div>
	);
};

export default Error;
