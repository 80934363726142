import React from 'react';
import CreativeCapabilitiesIcon from './../../assets/images/capabilitiesicon.png';
import VerticalSolutionsIcon from './../../assets/images/verticalicon.png';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Link,withRouter } from 'react-router-dom';
import './Cards.scss';
const useStyles = makeStyles(theme => ({
	paper: {
		padding: '5px',
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

const Cards = () => {
	const classes = useStyles();
	return (
		
			<div className="card flex-row-center">
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<Paper className={classes.paper}>
						<div className="border">
							<img src={CreativeCapabilitiesIcon} alt="Creative Capabilities" className="cc_icon" />
							<div className="content">
								<div className="heading uppercase">CREATIVE FEATURES</div>
								<div className="cardContent">
									Cutting Edge HTML5 functionalities for high impact, interactive mobile ad experiences.
								</div>
								<Button component={Link} variant="contained" className="button" to="/functionalities">
									See More
								</Button>
								{/* <Link to="/functionalities" variant="contained" className="button" >
									See More
								</Link> */}
								{/* <Button component={Link} to={item.link} color="primary" className="headerBtn upperCase">
										{item.name}
									</Button> */}
							</div>
						</div>
						<div className="borderBottom"></div>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Paper className={classes.paper}>
						<div className="border">
							<img src={VerticalSolutionsIcon} alt="VERTICAL SOLUTIONS" className="cc_icon" />
							<div className="content">
								<div className="heading uppercase">VERTICAL SOLUTIONS</div>
								<div className="cardContent">
									Hand picked, best in class creative examples for every industry type and campaign
									objectives.
								</div>
								<Button component={Link} variant="contained" className="button" to="/verticals">
									See More
								</Button>
							</div>
						</div>
						<div className="borderBottom"></div>
					</Paper>
				</Grid>
			</Grid>
		</div>
	
		// </div>
	);
};

export default Cards;
