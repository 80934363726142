import React from 'react';
import { makeStyles } from '@mui/styles';
import CreativeTypeCard from './../Common/CreativeTypeCard';
import './VerticalCard.scss';
const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: '5px',
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));
const VerticalCard = ({ vertical, selectVertical }) => {
	return <CreativeTypeCard details={vertical} type="vertical" />;
};

export default VerticalCard;
