import React from 'react';
import Button from '@mui/material/Button';
import VerticalExampleCard from './VerticalExampleCard';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';

import './VerticalExamples.scss';

const VerticalExamples = ({ examples, verticalType, creatives }) => {
	let exampleList = [];

	if (Array.isArray(examples) && examples.length) {
		exampleList = examples;
	} else {
		exampleList = creatives;
	}
	return (
		<div className="veticalExamplesContainer">
			<div className="topHeader flex-row-center">
				<div className="left uppercase">EXAMPLES</div>
				<div className="liner"></div>
				<div className="right">
					<Button variant="contained" className="button" href={`/gallery?verticals=${verticalType}`}>
						SEE MORE
					</Button>
				</div>
			</div>
			<div className="topHeaderMobile uppercase text-align-center">EXAMPLES</div>
			<div className="mainContainer">
				{exampleList ? (
					<div className="exampleList">
						{exampleList.map((details, index) => (
							<VerticalExampleCard details={details} key={index} />
						))}
					</div>
				) : (
					<></>
				)}

				<div className="carouselMobileContainer">
					{exampleList ? (
						<Carousel
							showArrows={true}
							showThumbs={false}
							showIndicators={false}
							infiniteLoop
							swipeable={true}
							className="creativeCarousel"
							statusFormatter={(current, total) => ``}
						>
							{exampleList.map((details, index) => {
								return <VerticalExampleCard details={details} key={index} />;
							})}
						</Carousel>
					) : (
						<></>
					)}
				</div>
			</div>
			<div className="seeMoreMobileContainer">
				<Button variant="contained" className="button" href={`/gallery?verticals=${verticalType}`}>
					SEE MORE
				</Button>
			</div>
		</div>
	);
};
const mapStateToProps = state => {
	return {
		examples: state.verticalReducer.selectedVertical.featured,
		creatives: state.verticalReducer.selectedVertical.creatives,
	};
};
export default connect(mapStateToProps)(VerticalExamples);
