import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import './Loader.scss';

const Loader = ({ open }) => {
	return (
		<Backdrop className="loader" open={open}>
			<div className="preloader loading ">
				<span className="slice"></span>
				<span className="slice"></span>
				<span className="slice"></span>
				<span className="slice"></span>
				<span className="slice"></span>
				<span className="slice"></span>
			</div>
		</Backdrop>
	);
};

export default Loader;
