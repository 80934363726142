import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { useNavigate, useLocation } from '@reach/router';
import {
	useLocation,useNavigate
  } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import hexbox from '../../assets/images/innerHexbox.png';
import hexicon from '../../assets/images/hexicon.png';
import { makeStyles } from '@mui/styles';
import { getCdnUrl, getFileType } from '../../utils/string';
import './CreativeTypeCardDetails.scss';
const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: '5px',
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));
const CreativeTypeCardDetails = ({ details }) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	let desc = details.description;
	if (desc != '' && desc != undefined) {
		const containsString = details.description.search('\n');
		console.log(containsString);
		if (containsString != -1) {
			desc = desc.replace(/(?:\r\n|\r|\n)/g, '\n \n');
		}
	}
	return (
		<div className="detailsContainer flex-row-start">
			<div className="verticalCardContainer">
				<div
					className="cardContainer text-align-center creative-card flex-row-center"
					square
					variant="outlined"
				>
					<div className="right">
						<div className="details">
							<div className="verticalIcon flex-row-center text-align-center">
								<img src={hexbox} alt="Creative Icon" />
								<div className="hexContainer">
									<img src={details.icon ? getCdnUrl(details.icon.url) : ''} alt="Creative Icon" />
								</div>
							</div>
							<ReactMarkdown>{desc}</ReactMarkdown>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreativeTypeCardDetails;
