import React, { useRef, useCallback, useEffect } from 'react';
import CreativeCard from './CreativeCard';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	fetchCreatives,
	resetCreatives,
	fetchFilterCreatives,
} from '../../redux/creative/CreativeActions';

import './CreativeList.scss';

const options = {
	root: null,
	rootMargin: '0px',
	threshold: 0,
};

const CreativeList = ({
	creatives,
	isCreativeLeft,
	fetchCreatives,
	resetCreatives,
	backupCreatives,
	fetchFilterCreatives,
	creativeCount,
	searchResults,
}) => {
	const creativesLoader = useRef(null);
	const location = useLocation();
	useEffect(() => {
		resetCreatives();
	}, [resetCreatives, location.search]);

	const loadMoreCreatives = useCallback(
		entries => {
			const target = entries[0];
			if (target.isIntersecting && isCreativeLeft && backupCreatives.length > 0) {
				fetchFilterCreatives(location.search);
			}
		},
		[isCreativeLeft, fetchFilterCreatives, location.search, backupCreatives],
	);

	useEffect(() => {
		const observer = new IntersectionObserver(loadMoreCreatives, options);
		if (creativesLoader.current) {
			const target = creativesLoader.current;
			observer.observe(target);
		}
		return () => {
			observer.disconnect();
		};
	}, [loadMoreCreatives]);

	return !isCreativeLeft ? (
		!creatives.length ? (
			<div className="emptyCreativeList text-align-center">
				No results found!
				<br />
				Try removing filters or check mockups to see more results
			</div>
		) : (
			<>
				<div className="creativeList">
					{creatives.map(creative => (
						<CreativeCard creative={creative} key={creative.id} />
					))}
				</div>
			</>
		)
	) : (
		<>
			<div className="creativeList">
				{creatives.map(creative => (
					<CreativeCard creative={creative} key={creative.id} />
				))}
			</div>
			<div
				ref={creativesLoader}
				className={
					creatives.length
						? 'creativesLoader flex-row-center'
						: 'creativesLoader flex-row-center invisible'
				}
			>
				<CircularProgress />
			</div>
		</>
	);
};

const mapStateToProps = state => {
	return {
		creatives: state.creativeReducer.creatives,
		isCreativeLeft: state.creativeReducer.isCreativeLeft,
		backupCreatives: state.creativeReducer.backupCreatives,
		creativeCount: state.creativeReducer.creativeCount,
		searchInput: state.creativeReducer.searchInput,
		searchResults: state.searchReducer.searchResults,
	};
};

export default connect(mapStateToProps, { fetchCreatives, resetCreatives, fetchFilterCreatives })(
	CreativeList,
);
