import React from 'react';
import Faq from './Faq';
import './FaqList.scss';

const FaqList = ({ faqs }) => {
	console.log('faqs', faqs);
	return faqs.length ? (
		<div className="faqList">
			<div className="container">
				{faqs.map(faq => (
					<Faq faq={faq} key={faq.id} />
				))}
			</div>
		</div>
	) : (
		<></>
	);
};

export default FaqList;
