import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactMarkdown from 'react-markdown';
import { fireEvent } from '../../utils/tracking';
import './Faq.scss';

const Faq = ({ faq, key }) => {
	return (
		<Accordion className="faq">
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				onClick={() => fireEvent('Faqs Page', 'Click', `Faq ${faq.id} Clicked`)}
			>
				<ReactMarkdown className="faqQuestion">{faq.question}</ReactMarkdown>
			</AccordionSummary>
			<AccordionDetails>
				<ReactMarkdown className="faqAnswer">{faq.answer}</ReactMarkdown>
			</AccordionDetails>
		</Accordion>
	);
};

export default Faq;
