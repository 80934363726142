import React from 'react';
import { connect } from 'react-redux';
// import { useLocation } from '@reach/router';
import { fireEvent } from '../../utils/tracking';
import {useLocation} from "react-router-dom";
import './CreativeTags.scss';

const handleClick = tagName => {
	fireEvent('Creative Detail Page', 'Click', `${tagName} Tag Clicked`);
};

const CreativeTagsForModal = ({ selectedCreative = {} }) => {
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	let postsales = '';
	const token = query.get('postsales');
	if (token != null) {
		postsales = 'postsales';
	}
	return (
		<div className="creativeTags">
			<div className="creativeTagsContent flex-row-valign">
				{selectedCreative.region && (
					<a
						href={`${window.location.origin}/gallery?${postsales}&search=${selectedCreative.region.name}`}
						onClick={() => handleClick(selectedCreative.region.name)}
						className="creativeTag flex-row-center capitalize text-align-center"
					>
						{selectedCreative.region.name}
					</a>
				)}
				{selectedCreative.brand && (
					<a
						href={`${window.location.origin}/gallery?${postsales}&brands=${selectedCreative.brand.name}`}
						onClick={() => handleClick(selectedCreative.brand.name)}
						className="creativeTag flex-row-center capitalize text-align-center"
					>
						{selectedCreative.brand.name}
					</a>
				)}
				{selectedCreative.vertical && (
					<a
						href={`${window.location.origin}/gallery?${postsales}&verticals=${selectedCreative.vertical.name}`}
						onClick={() => handleClick(selectedCreative.vertical.name)}
						className="creativeTag flex-row-center capitalize text-align-center"
					>
						{selectedCreative.vertical.name}
					</a>
				)}
				{Array.isArray(selectedCreative.formats) &&
					selectedCreative.formats.map(format => (
						<a
							href={`${window.location.origin}/gallery?${postsales}&formats=${format.name}`}
							onClick={() => handleClick(format.name)}
							className="creativeTag flex-row-center capitalize text-align-center"
							key={format.id}
						>
							{format.name}
						</a>
					))}
				{Array.isArray(selectedCreative.functionalities) &&
					selectedCreative.functionalities.map(functionality => (
						<a
							href={`${window.location.origin}/gallery?${postsales}&functionalities=${functionality.name}`}
							onClick={() => handleClick(functionality.name)}
							className="creativeTag flex-row-center capitalize text-align-center"
							key={functionality.id}
						>
							{functionality.name}
						</a>
					))}
				{Array.isArray(selectedCreative.specialtags) &&
					selectedCreative.specialtags.map(specialtag => (
						<a
							href={`${window.location.origin}/gallery?${postsales}&search=${specialtag.name}`}
							onClick={() => handleClick(specialtag.name)}
							className="creativeTag flex-row-center capitalize text-align-center"
							key={specialtag.id}
						>
							{specialtag.name}
						</a>
					))}
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		selectedCreative: state.creativeReducer.selectedCreative,
	};
};

export default connect(mapStateToProps)(CreativeTagsForModal);
