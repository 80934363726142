import React, { useEffect } from 'react';
import Loader from '../Loader';
import Error from '../Error';
import AwardWinningList from './AwardWinningList';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { fetchAwardWinningCreatives } from '../../redux/home/HomeActions';

import './AwardWinningCreatives.scss';

const AwardWinningCreatives = ({
	loading,
	error,
	fetchAwardWinningCreatives,
	selectedCreatives,
}) => {
	useEffect(() => {
		if (!Object.keys(selectedCreatives).length) {
			fetchAwardWinningCreatives();
		}
	}, [loading, error, fetchAwardWinningCreatives, selectedCreatives]);
	return error ? (
		<Error error={error} />
	) : loading ? (
		<>
			<Loader open={loading} />
		</>
	) : (
		<>
			<div className="awardWinningCreatives text-align-center">
				<div className="heading uppercase">AWARD WINNING CREATIVES</div>
				<div className="description">
					Explore InMobi's award winning ads that spark exploration and elevate brand experience!
				</div>
				<AwardWinningList />
				<Button
					variant="contained"
					className="button"
					href="/gallery?specialtags=Award%20Winning&postsales"
				>
					See More
				</Button>
			</div>
		</>
	);
};
const mapStateToProps = state => {
	return {
		loading: state.homeReducer.loading,
		selectedCreatives: state.homeReducer.selectedCreatives,
	};
};
export default connect(mapStateToProps, { fetchAwardWinningCreatives })(AwardWinningCreatives);
