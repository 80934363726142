import React from 'react';
import Grid from '@mui/material/Grid';
import FunctionalityCard from './FunctionalityCard';
import './FunctionalityList.scss';
const FunctionalityList = ({ functionalities }) => (
	<div className="creative-list-ctn">
		<Grid container spacing={3}>
			{functionalities.map(functionality => (
				<FunctionalityCard functionality={functionality} key={functionality.id} />
			))}
		</Grid>
	</div>
);

export default FunctionalityList;
