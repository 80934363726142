import React from 'react';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import AwardWinningCard from './AwardWinningCard';
import './AwardWinningList.scss';

const AwardWinningList = ({ examples }) => {
	let exampleList = [];
	if (examples) {
		exampleList = examples;
	}

	return (
		<div className="awardWinningList">
			<div className="mainContainer">
				<div className="exampleList">
					{exampleList.map((details, index) => (
						<AwardWinningCard key={index} details={details} />
					))}
				</div>
				<div className="carouselMobileContainer">
					<Carousel
						showArrows={true}
						showThumbs={false}
						showIndicators={false}
						infiniteLoop
						swipeable={true}
						className="creativeCarousel"
						statusFormatter={(current, total) => ``}
					>
						{exampleList.map((details, index) => {
							return <AwardWinningCard key={index} details={details} />;
						})}
					</Carousel>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		examples: state.homeReducer.selectedCreatives.creatives,
	};
};
export default connect(mapStateToProps, null)(AwardWinningList);
