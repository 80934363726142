import React from 'react';
import { Link,withRouter } from 'react-router-dom';
import homeScreenHex from '../../assets/images/homeheaderimage.png';
import VisitGallery from '../../assets/images/visitgallerybtn.png';

import './HomeBanner.scss';

const HomeBanner = () => {
	return (
	<div className="banner flex-row-center">
		<div className="content">
			<div className="topDetails">
				<div className="topBannerContainer flex-row-center">
					<div className="hexagonbg flex-column-start">
						<img src={homeScreenHex} alt="home-screen-hex" />
					</div>
				</div>
				<div className="galleryIcon">
					<Link to="/gallery?postsales" className="button text-align-center">
						<img src={VisitGallery} alt="Visit-gallery" />
					</Link>
				</div>
			</div>
		</div>
	</div>
);
}

export default HomeBanner;
