import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import './NoMockSwitch.scss';

const NoMockSwitch = ({ specialTags }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [noMocks, setNoMocks] = useState(false);

	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const token = query.get('postsales');
		if (token != null) {
			setNoMocks(true);
		}
	}, [location.search]);
	const handleChange = () => {
		setNoMocks(!noMocks);
		if (!noMocks) {
			location.search
				? navigate(`${location.href}&postsales`)
				: navigate(`${location.href}?postsales`);
		} else {
			const removedFiltersStringArray = ['postsales'];
			const currentFilterString = location.search.substr(1);
			const filterStringArray = currentFilterString.split('&');
			const updatedFilterStringArray = filterStringArray.filter(
				filterString => !removedFiltersStringArray.includes(filterString),
			);
			updatedFilterStringArray.length
				? navigate(`${location.origin}${location.pathname}?${updatedFilterStringArray.join('&')}`)
				: navigate(`${location.origin}${location.pathname}`);
		}
	};

	const newSpecialTags = [];
	for (let i = 0; i < specialTags.length; i++) {
		console.log(specialTags[i]);
		newSpecialTags.push(specialTags[i]);
	}

	return (
		<>
			<label className="toggleSwitch nolabel">
				<input type="checkbox" checked={!noMocks} onChange={() => handleChange()} />
				<span>
					<span>Show Mocks</span>
					<span>Hide Mocks</span>
				</span>
				<a></a>
			</label>
		</>
	);
};
const mapStateToProps = state => {
	return {
		specialTags: state.creativeReducer.specialTags,
	};
};

export default connect(mapStateToProps, null)(NoMockSwitch);
