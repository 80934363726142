import React from 'react';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { checkLength, getCdnUrl } from '../../utils/string';
import { fireEvent } from '../../utils/tracking';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import './CreativeTypeCard.scss';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: '5px',
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));
const CreativeCardType = ({ details, type }) => {
	console.log(details);
	const classes = useStyles();
	const handleClick = () => {
		fireEvent('Docs Page', 'Click', `${details.name} Clicked`);
	};
	return (
		<Grid item xs={12} sm={6} md={4} lg={3} xl={3} className="functionalityCardContainer">
			<Paper className="cardContainer creative-card" square variant="outlined">
				{}
				<Link to={`/${type}/${details.slug}`} onClick={handleClick}>
					<div className="border boxContainer flex-column-center" title={details.name}>
						<div className="creative-card-content">
							<div className="flex-column flex-space-between">
								<div className="card-icon flex-row flex-center">
									<div className="verticalIcon text-align-center flex-row-center">
										<div className="hexContainer">
											<img src={details.icon ? getCdnUrl(details.icon.url) : ''} alt="Creative Icon" />
										</div>
									</div>
								</div>
							</div>
							<div className="flex-column flex-space-between">
								<div className="heading uppercase">{checkLength(details.name, 24, 20)}</div>
							</div>
						</div>
					</div>
					<div className="borderBottom"></div>
				</Link>
			</Paper>
		</Grid>
	);
};
export default CreativeCardType;
