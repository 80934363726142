import React from 'react';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import CreativeDetailSection from './CreativeDetailSection';
import { closeDialogModal } from '../../redux/creative/CreativeActions';
import { hideLoader } from './../../redux/functionality/FunctionalityActions';
import CreativeTagsForModal from './CreativeTagsForModal';
import ModalAuthorize from './ModalAuthorize';

import './CreativeModal.scss';

import CancelIcon from '@mui/icons-material/Cancel';
const useStyles = makeStyles(theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid gray',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
}));
const CreativeModal = ({
	showCreativeModalBox,
	closeDialogModal,
	loading,
	authorizationFailure,
	hideLoader,
}) => {
	const handleClose = () => {
		closeDialogModal();
	};
	return authorizationFailure ? (
		<>
			<div
				className={showCreativeModalBox ? 'md-modal md-effect-1 md-show' : 'md-modal md-effect-1'}
				id="modal-1"
			>
				<div className="closeModalIcon authorizeIcon" onClick={handleClose}>
					<CancelIcon />
				</div>
				<div className="md-content">
					<ModalAuthorize />
				</div>
			</div>
			<div className="md-overlay" onClick={handleClose}></div>
		</>
	) : (
		<>
			<div
				className={showCreativeModalBox ? 'md-modal md-effect-1 md-show' : 'md-modal md-effect-1'}
				id="modal-1"
			>
				<div className="closeModalIcon" onClick={handleClose}>
					<CancelIcon />
				</div>
				<div className="md-content">
					<CreativeTagsForModal />
					<CreativeDetailSection />
				</div>
			</div>
			<div className="md-overlay" onClick={handleClose}></div>
		</>
	);
};
const mapDispatchToProps = dispatch => {
	return {
		hideLoader: () => dispatch(hideLoader()),
		closeDialogModal: () => dispatch(closeDialogModal()),
	};
};
const mapStateToProps = state => {
	return {
		showCreativeModalBox: state.creativeReducer.showCreativeModalBox,
		loading: state.creativeReducer.loading,
		authorizationFailure: state.creativeReducer.authorizationFailure,
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CreativeModal);
