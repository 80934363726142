import React from 'react';
import FiltersSection from './FiltersSection';
import CreativeList from './CreativeList';
import './CreativeListingSection.scss';
import TagList from './TagList';

const CreativeListingSection = () => (
	<div className="creativeListingSection">
		<FiltersSection />
		<TagList />
		<CreativeList />
	</div>
);

export default CreativeListingSection;
