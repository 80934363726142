import React from 'react';
import SearchBar from './SearchBar';
import './HomeBanner.scss';

const HomeBanner = ({ heading, showSearchBar }) => (
	<div className="homeBanner flex-row-center">
		{showSearchBar ? (
			<div className="bannerContent flex-column-center">
				<h1 className="bannerHeading text-align-center uppercase ">{heading}</h1>
				<SearchBar />
			</div>
		) : (
			''
		)}
	</div>
);

export default HomeBanner;
