import React, { useEffect } from 'react';
// import { Link } from '@reach/router';
import Button from '@mui/material/Button';
import AdFormatExampleCard from './AdFormatExampleCard';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
// import { useLocation, useNavigate } from '@reach/router';
import {
	createBrowserRouter,
	RouterProvider,
	Route,
	Link,useLocation,useNavigate
  } from "react-router-dom";
import './AdFormatExamples.scss';
import examplephonebg from '../../assets/images/examplephonebg.png';

const AdFormatExamples = ({ examples, adFormatType, creatives }) => {
	const navigate = useNavigate();
	const location = useLocation();
	let exampleList = [];
	if (Array.isArray(examples) && examples.length) {
		exampleList = examples;
	} else {
		exampleList = creatives;
	}

	return (
		<div className="examplesContainer">
			<div className="topHeader flex-row-center">
				<div className="left">EXAMPLES</div>
				<div className="liner"></div>
				<div className="right">
					<Button
						variant="contained"
						className="button"
						href={`/gallery?functionalities=${adFormatType}`}
					>
						SEE MORE
					</Button>
				</div>
			</div>
			<div className="topHeaderMobile text-align-center">EXAMPLES</div>
			<div className="mainContainer">
				{exampleList ? (
					<div className="exampleList">
						{exampleList.map(details => (
							<AdFormatExampleCard details={details} />
						))}
					</div>
				) : (
					<>
						<div className="dontExistMsg text-align-center">No Examples Found</div>
					</>
				)}

				<div className="carouselMobileContainer">
					{exampleList ? (
						<Carousel
							showArrows={true}
							showThumbs={false}
							showIndicators={false}
							infiniteLoop
							swipeable={true}
							className="creativeCarousel"
							statusFormatter={(current, total) => ``}
						>
							{exampleList.map((details, index) => {
								return <AdFormatExampleCard details={details} />;
							})}
						</Carousel>
					) : (
						<>
							<div className="dontExistMsgMobile text-align-center">No Examples Found</div>
						</>
					)}
				</div>
			</div>
			{exampleList ? (
				<div className="seeMoreMobileContainer">
					<Button
						variant="contained"
						className="button"
						href={`/gallery?functionalities=${adFormatType}`}
					>
						SEE MORE
					</Button>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};
const mapStateToProps = state => {
	return {
		examples: state.functionalityReducer.selectedFunctionality.featured,
		creatives: state.functionalityReducer.selectedFunctionality.creatives,
	};
};
export default connect(mapStateToProps)(AdFormatExamples);
