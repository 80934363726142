import React from 'react';
import { connect } from 'react-redux';
import { selectFunctionality } from '../../redux/functionality/FunctionalityActions';
import { makeStyles } from '@mui/styles';

import CreativeTypeCard from './../Common/CreativeTypeCard';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: '5px',
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));
const FunctionalityCard = ({ functionality, selectFunctionality }) => {
	return <CreativeTypeCard details={functionality} type="functionality" />;
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		selectFunctionality: () => dispatch(selectFunctionality(ownProps.functionality.slug)),
	};
};

export default connect(null, mapDispatchToProps)(FunctionalityCard);
