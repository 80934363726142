import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import CopyToClipBoard from './../../assets/images/copyToClipBoard.png';

import './CreativePreviewLink.scss';

const CreativePreviewLink = ({ selectedCreative }) => {
	const [copySuccess, setCopySuccess] = useState(false);
	const location = useLocation();
	const copyToClipboard = id => {
		navigator.clipboard.writeText(id);
		setCopySuccess(value => !value);
	};
	return (
		<div className={copySuccess ? 'previewLinkContainer linkCopied' : 'previewLinkContainer'}>
			<img
				alt="copy-to-clipboard"
				src={CopyToClipBoard}
				onClick={() => copyToClipboard(location.origin + '/creative/' + selectedCreative.slug)}
			/>
		</div>
	);
};
export default CreativePreviewLink;
