import React from 'react';
import Button from '@mui/material/Button';
import Lock from './../../assets/images/lock.png';
import './Authorize.scss';

const ModalAuthorize = () => {
	return (
		<div className="authorize">
			<div className="lockImageContainer">
				<img src={Lock} alt="Lock Icon" className="lockIcon" />
			</div>

			<span>Access Restricted</span>
			<span>Please login to access this content</span>
			<Button
				variant="contained"
				className="button"
				href={`${process.env.REACT_APP_API_URL}/connect/microsoft`}
			>
				Login
			</Button>
		</div>
	);
};

export default ModalAuthorize;
