import React, { useEffect } from 'react';
// import { makeStyles, Theme, createStyles } from '@mui/style';
// import Modal from '@material-ui/core/Modal';
import Loader from '../components/Loader';
import PageTitle from '../components/PageTitle';
import Header from '../components/Header';
import HomeBanner from '../components/HomeBanner';
import CreativeListingSection from '../components/Creative/CreativeListingSection';
import Error from '../components/Error';
import { connect } from 'react-redux';
import { fetchAllCreatives } from '../redux/creative/CreativeActions';
import { fetchFilters } from '../redux/creative/CreativeActions';
import CreativeModal from '../components/Creative/CreativeModal';

const HomeDemo = ({ loading, error, fetchAllCreatives, fetchFilters }) => {
	useEffect(() => {
		fetchAllCreatives();
		fetchFilters();
	}, [fetchAllCreatives, fetchFilters]);

	return error ? (
		<Error error={error} />
	) : (
		<>
			<Loader open={loading} />
			<CreativeModal />
			<PageTitle />
			<Header button="menu" />
			<HomeBanner heading="" showSearchBar={true} />
			<CreativeListingSection />
		</>
	);
};

const mapStateToProps = state => {
	return {
		loading: state.creativeReducer.loading,
		error: state.creativeReducer.error,
	};
};
const mapDispatchToProps = {
	fetchAllCreatives: fetchAllCreatives,
	fetchFilters: fetchFilters,
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeDemo);
