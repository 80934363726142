import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactMarkdown from 'react-markdown';
// import { useNavigate, useLocation } from '@reach/router';
import {
	createBrowserRouter,
	RouterProvider,
	Route,
	Link,useLocation,useNavigate
  } from "react-router-dom";
import './AdFormatDetails.scss';
import mobileScreen from '../../assets/images/hexagontrans.png';
import hexbox from '../../assets/images/innerHexbox.png';
import hexicon from '../../assets/images/hexicon.png';
import { getCdnUrl, getFileType } from '../../utils/string';
import { connect } from 'react-redux';
import Paper from '@mui/material/Paper';
import FaqList from './../Faq/FaqList';
import CreativeTypeCardDetails from '../Common/CreativeTypeCardDetails';
const AdFormatDetails = ({ details }) => {
	console.log('details', details.creatives);

	const navigate = useNavigate();
	const location = useLocation();
	let reportingList = [];
	let osPlatform = [];
	let faqs = [];
	let brand_objectives = [];
	let desc = details.description;
	if (Array.isArray(details.reporting_metrics) && details.reporting_metrics.length) {
		reportingList = details.reporting_metrics;
	}
	if (Array.isArray(details.osplatforms) && details.osplatforms.length) {
		osPlatform = details.osplatforms;
		console.log(osPlatform);
	}
	if (Array.isArray(details.faqs) && details.faqs.length) {
		faqs = details.faqs;
		console.log(faqs.length);
	}
	if (Array.isArray(details.brand_objectives) && details.brand_objectives.length) {
		brand_objectives = details.brand_objectives;
		console.log(brand_objectives);
	}
	if (desc != '' && desc != undefined) {
		const containsString = details.description.search('\n');
		console.log(containsString);
		if (containsString != -1) {
			desc = desc.replace(/(?:\r\n|\r|\n)/g, '\n \n');
		}
	}

	return (
		<>
			<div className="adFormatMainContainer">
				<div className="adFormatDetails-Container text-align-center">
					<div className="ContainerContent">
						<div className="backButtonContent">
							<IconButton
								className="backBtn"
								onClick={() => {
									navigate(-1);
								}}
							>
								<ArrowBackIcon className="backIcon" />
							</IconButton>
						</div>
						<div className="leftArea">
							<div className="leftContainerHeader">
								<div className="title">AD FORMULA</div>
								<div className="formatType uppercase">{details.name}</div>
							</div>
							<div className="techDetails">
								<CreativeTypeCardDetails details={details} />
								<div className="matricsContainer flex-row-center">
									<Paper className="cardContainer creative-card-container " square variant="outlined">
										<div className="eventsSec1">
											<div className="metrix1">
												{reportingList.length > 0 ? (
													<div className="sectionEvents">
														<b>CAPTURABLE USER EVENTS</b>

														{reportingList.map(matrix => {
															console.log(matrix, 'matrix');
															return <div className="userEvent">{matrix.name}</div>;
														})}
													</div>
												) : (
													<></>
												)}

												{osPlatform.length > 0 ? (
													<div className="sectionCompatibility">
														<b>Compatibility</b>
														{osPlatform.map(matrix => {
															console.log(matrix, 'matrix');
															return <div className="userEvent">{matrix.name}</div>;
														})}
													</div>
												) : (
													<></>
												)}
											</div>
										</div>
										<div className="eventsSec2">
											<div className="metrix">
												<div className="sectionPerformance">
													<b>PERFORMANCE BENCHMARKS</b>
													<div className="userEvent">Interaction Rate : {details.interaction_rate}</div>
													<div className="userEvent">Avg Dwell Time : 20 secs</div>
												</div>
												{brand_objectives.length > 0 ? (
													<div className="sectionBestSuitFor">
														<b>CAMPAIGNS BEST SUITED FOR</b>
														{brand_objectives.map(bj => {
															console.log(bj, 'matrix');
															return <div className="userEvent">{bj.name}</div>;
														})}
													</div>
												) : (
													<></>
												)}
											</div>
										</div>
										{faqs.length > 0 ? (
											<div className="eventsSec3">
												<div className="metrix">
													<div className="sectionPerformance">
														<b>FAQS</b>
														<FaqList faqs={faqs} />
													</div>
												</div>
											</div>
										) : (
											<></>
										)}
									</Paper>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="topIconLiner flex-row-space-between">
					<div className="iconContainer">
						<img src={hexicon} alt="hexicon" />
					</div>
				</div>
			</div>
		</>
	);
};
const mapStateToProps = state => {
	return {
		details: state.functionalityReducer.selectedFunctionality,
	};
};
export default connect(mapStateToProps, null)(AdFormatDetails);
