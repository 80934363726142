import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import Slide from '@mui/material/Slide';
// import useScrollTrigger from '@mui/material/useScrollTrigger';
// import { Link } from '@reach/router';
import {
	createBrowserRouter,
	RouterProvider,
	Route,
	Link,useLocation
  } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppDrawer from './AppDrawer';
import logo from '../assets/images/creative-labs-logo.png';
import { fireEvent } from '../utils/tracking';
import Button from '@mui/material/Button';
import './Header.scss';
// import { useLocation } from '@reach/router';
import { connect } from 'react-redux';
import { Fragment } from 'react';
// const HideOnScroll = ({ children }) => {
// 	const trigger = useScrollTrigger();
// 	return (
// 		<Slide appear={false} direction="down" in={!trigger}>
// 			{children}
// 		</Slide>
// 	);
// };

const menuItems = [
	{
		name: 'GALLERY',
		link: '/gallery?postsales',
		icon: 'gallery',
	},
	{
		name: 'FEATURES',
		link: '/functionalities',
		icon: 'Functionalities',
	},
	{
		name: 'VERTICALS',
		link: '/verticals',
		icon: 'Ad Verticals',
	},
];

const Header = ({ button }) => {
	const location = useLocation();
	console.log(location)
	const [drawerOpen, setDrawerOpen] = useState(false);
	const toggleDrawer = open => {
		setDrawerOpen(open);
	};
	const userLogin = () => {
		localStorage.setItem('ru', location.pathname);
		window.location.href = `${process.env.REACT_APP_API_URL}/connect/microsoft`;
	};
	const userLogout = () => {
		localStorage.removeItem('jwt');
		localStorage.removeItem('email');
		window.location.reload();
	};
	const isLoggedInn = localStorage.getItem('jwt');

	return (
		<>
			<AppBar className="header">
				<Toolbar className="toolbar">
					<div className="headerContent flex-row-space-between">
						{button === 'back' ? (
							<IconButton
								className="backBtn"
								onClick={() => {
									fireEvent('Header', 'Click', `Back Button Clicked`);
									window.history.back();
								}}
							>
								<ArrowBackIcon className="backIcon" />
							</IconButton>
						) : button === 'menu' && menuItems.length ? (
							<div className="menuBtnCtn flex-row-center">
								<div className="menuHexagon">&#x2B22;</div>
								<IconButton
									onClick={() => {
										fireEvent('Header', 'Click', `Menu Button Clicked`);
										toggleDrawer(true);
									}}
									className="menuBtn"
								>
									<MenuRoundedIcon className="menuIcon" />
								</IconButton>
							</div>
						) : null}
						<div className="headerLogoCtn flex-row-start">
							<Link
								to="/"
								className="headerLogo flex-row-center"
								onClick={() => fireEvent('Header', 'Click', `Logo Clicked`)}
							>
								<img src={logo} alt="ThinkLoud Logo" />
							</Link>
						</div>
						<div className="headerBtnCtn flex-row-end">
							{menuItems.map((item, index) => (
								<Fragment key={`${item.name}-${index}`}>
									<Button component={Link} to={item.link} color="primary" className="headerBtn upperCase">
										{item.name}
									</Button>
									{/* <Link to="/functionalities">Home</Link> */}
									<div className="separator">|</div>
								</Fragment>
							))}
							{!isLoggedInn ? (
								<>
									<Button color="primary" onClick={() => userLogin()} className="headerBtn upperCase">
										LOGIN
									</Button>
								</>
							) : (
								<Button color="primary" onClick={() => userLogout()} className="headerBtn upperCase">
									LOGOUT
								</Button>
							)}
						</div>
					</div>
				</Toolbar>
			</AppBar>
			<AppDrawer
				drawerOpen={drawerOpen}
				toggleDrawer={toggleDrawer}
				menuItems={menuItems}
				isLoggedInn={isLoggedInn}
			/>
		</>
	);
};

export default Header;
