import React from 'react';
import { getCdnUrl } from '../../utils/string';
import examplephonebg from '../../assets/images/phonebgnew.png';
import { getMonthName } from '../../utils/date';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { connect } from 'react-redux';
import { openDialogModal } from './../../redux/creative/CreativeActions';
import { hideLoader } from './../../redux/vertical/VerticalActions';

import './VerticalExampleCard.scss';

const VerticalExampleCard = ({ details, openModalBox, hideLoader }) => {
	const openCreativeModal = id => {
		openModalBox(id);
		hideLoader();
	};
	const creativeDate = new Date(details.campaign_created_at);
	const formattedDate = `${getMonthName(creativeDate.getMonth())} ${creativeDate.getFullYear()}`;
	return (
		<div className="exampleContainer">
			<div
				className="exampleContainer"
				onClick={() => {
					openCreativeModal(details);
				}}
			>
				<img src={examplephonebg} className="phoneBg" alt="phone-bg" />
				<div className="exampleCardContentSection">
					<div className="cardContent">
						<div className="cardViewBtn">View</div>
						<div className="cardTitle">{details.title}</div>
						<div className="cardRegionDate">
							{details.region ? `${details.region.name}, ${formattedDate}` : `${formattedDate}`}
						</div>
					</div>
					<img src={details.thumbnail ? getCdnUrl(details.thumbnail.url) : ''} alt="details-thumbnail" />
				</div>
			</div>
		</div>
	);
};
const mapDispatchToProps = dispatch => {
	return {
		hideLoader: () => dispatch(hideLoader()),
		openModalBox: id => dispatch(openDialogModal(id)),
	};
};

export default connect(null, mapDispatchToProps)(VerticalExampleCard);
