import React from 'react';
//import Carousel from 'react-material-ui-carousel';
import { Carousel } from 'react-responsive-carousel';
import { connect } from 'react-redux';
import { getCdnUrl } from '../../utils/string';
import mobileScreen from '../../assets/images/phonebgnew.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import LoaderImage from './../../assets/images/sample.gif';
import ImageLoader from './../ImageLoader';
import './CreativeCarousel.scss';

const CreativeCarousel = ({ carouselItems = [], video = {} }) => {
	const [loaded, setLoaded] = React.useState(false);
	function handleImageLoad() {
		setLoaded(true);
	}
	return (video && video.url) || carouselItems.length ? (
		<div className="creativeCarouselSection">
			<div className="creativeCarouselCtn">
				<img src={mobileScreen} alt="Mobile Screen" width="100%" />
				{video && video.url ? (
					<>
						<div
							style={{ visibility: loaded ? 'hidden' : 'visible' }}
							className="imageLoaderContainer flex-row-center text-align-center"
						>
							<ImageLoader />
						</div>
						<div
							style={{ visibility: loaded ? 'visible' : 'hidden' }}
							className="imageLoaderContainer flex-row-center text-align-center"
						>
							<video
								muted
								autoPlay
								playsInline
								preload="auto"
								loop
								controls
								src={getCdnUrl(video.url)}
								className="creativeCarousel"
								onLoadedData={handleImageLoad}
							></video>
						</div>
					</>
				) : (
					// <Carousel indicators={false} className="creativeCarousel" animation="slide" cycleNavigation={true}>
					// 	{carouselItems.map((item, index) => {
					// 		return <img src={getCdnUrl(item.url)} alt="" width="100%" key={index} />;
					// 	})}
					// </Carousel>
					<Carousel
						autoPlay
						showArrows={true}
						showThumbs={false}
						showIndicators={false}
						infiniteLoop
						interval={2000}
						className="creativeCarousel"
						statusFormatter={(current, total) => ``}
					>
						{carouselItems.map((item, index) => {
							return (
								<div key={index}>
									<img src={getCdnUrl(item.url)} alt="" width="100%" key={index} />
								</div>
							);
						})}
					</Carousel>
				)}
			</div>
		</div>
	) : null;
};

const mapStateToProps = state => {
	return {
		carouselItems: state.creativeReducer.selectedCreative.carousel,
		video: state.creativeReducer.selectedCreative.video,
	};
};

export default connect(mapStateToProps)(CreativeCarousel);
